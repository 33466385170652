// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** green-primary **/
  --ion-color-green-primary: #a7c03d;
  --ion-color-green-primary-rgb: 167, 192, 61;
  --ion-color-green-primary-contrast: #000000;
  --ion-color-green-primary-contrast-rgb: 0, 0, 0;
  --ion-color-green-primary-shade: #93a936;
  --ion-color-green-primary-tint: #b0c650;

  /** green-secondary **/
  --ion-color-green-secondary: #556318;
  --ion-color-green-secondary-rgb: 85, 99, 24;
  --ion-color-green-secondary-contrast: #ffffff;
  --ion-color-green-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-green-secondary-shade: #4b5715;
  --ion-color-green-secondary-tint: #66732f;

  /** green-tertiary **/
  --ion-color-green-tertiary: #313910;
  --ion-color-green-tertiary-rgb: 49, 57, 16;
  --ion-color-green-tertiary-contrast: #ffffff;
  --ion-color-green-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-green-tertiary-shade: #2b320e;
  --ion-color-green-tertiary-tint: #464d28;

  /** blue-primary **/
  --ion-color-blue-primary: #16bcef;
  --ion-color-blue-primary-rgb: 22, 188, 239;
  --ion-color-blue-primary-contrast: #000000;
  --ion-color-blue-primary-contrast-rgb: 0, 0, 0;
  --ion-color-blue-primary-shade: #13a5d2;
  --ion-color-blue-primary-tint: #2dc3f1;

  /** blue-secondary **/
  --ion-color-blue-secondary: #1764a6;
  --ion-color-blue-secondary-rgb: 23, 100, 166;
  --ion-color-blue-secondary-contrast: #ffffff;
  --ion-color-blue-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-blue-secondary-shade: #145892;
  --ion-color-blue-secondary-tint: #2e74af;

  /** blue-tertiary **/
  --ion-color-blue-tertiary: #002456;
  --ion-color-blue-tertiary-rgb: 0, 36, 86;
  --ion-color-blue-tertiary-contrast: #ffffff;
  --ion-color-blue-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-blue-tertiary-shade: #00204c;
  --ion-color-blue-tertiary-tint: #1a3a67;

  /** blue-quaternary **/
  --ion-color-blue-quaternary: #1350a5;
  --ion-color-blue-quaternary-rgb: 19, 80, 165;
  --ion-color-blue-quaternary-contrast: #ffffff;
  --ion-color-blue-quaternary-contrast-rgb: 255, 255, 255;
  --ion-color-blue-quaternary-shade: #114691;
  --ion-color-blue-quaternary-tint: #2b62ae;

  /** blue-fifth **/
  --ion-color-blue-fifth: #0184c7;
  --ion-color-blue-fifth-rgb: 1, 132, 199;
  --ion-color-blue-fifth-contrast: #ffffff;
  --ion-color-blue-fifth-contrast-rgb: 255, 255, 255;
  --ion-color-blue-fifth-shade: #0174af;
  --ion-color-blue-fifth-tint: #1a90cd;

  /** blue-header **/
  --ion-color-blue-header: #032a41;
  --ion-color-blue-header-rgb: 3, 42, 65;
  --ion-color-blue-header-contrast: #ffffff;
  --ion-color-blue-header-contrast-rgb: 255, 255, 255;
  --ion-color-blue-header-shade: #032539;
  --ion-color-blue-header-tint: #1c3f54;

  /** blue-menu **/
  --ion-color-blue-menu: #000d1f;
  --ion-color-blue-menu-rgb: 0, 13, 31;
  --ion-color-blue-menu-contrast: #ffffff;
  --ion-color-blue-menu-contrast-rgb: 255, 255, 255;
  --ion-color-blue-menu-shade: #000b1b;
  --ion-color-blue-menu-tint: #1a2535;

  /** blue-menu-secondary **/
  --ion-color-blue-menu-secondary: #00337b;
  --ion-color-blue-menu-secondary-rgb: 0, 51, 123;
  --ion-color-blue-menu-secondary-contrast: #ffffff;
  --ion-color-blue-menu-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-blue-menu-secondary-shade: #002d6c;
  --ion-color-blue-menu-secondary-tint: #1a4788;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  // novo layout
  /** corretor-menu **/
  --ion-color-corretor-menu: #00a7ba;
  --ion-color-corretor-menu-rgb: 0, 167, 186;
  --ion-color-corretor-menu-contrast: #ffffff;
  --ion-color-corretor-menu-contrast-rgb: 255, 255, 255;
  --ion-color-corretor-menu-shade: #0093a4;
  --ion-color-corretor-menu-tint: #1ab0c1;

  /** corretor-menu-secondary **/
  --ion-color-corretor-menu-secondary: #004850;
  --ion-color-corretor-menu-secondary-rgb: 0, 72, 80;
  --ion-color-corretor-menu-secondary-contrast: #ffffff;
  --ion-color-corretor-menu-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-corretor-menu-secondary-shade: #003f46;
  --ion-color-corretor-menu-secondary-tint: #1a5a62;

  /** corretor-blue-primary **/
  --ion-color-corretor-blue-primary: #00a7ba;
  --ion-color-corretor-blue-primary-rgb: 0, 167, 186;
  --ion-color-corretor-blue-primary-contrast: #ffffff;
  --ion-color-corretor-blue-primary-contrast-rgb: 255, 255, 255;
  --ion-color-corretor-blue-primary-shade: #0093a4;
  --ion-color-corretor-blue-primary-tint: #1ab0c1;

  /** corretor-blue-secondary **/
  --ion-color-corretor-blue-secondary: #004850;
  --ion-color-corretor-blue-secondary-rgb: 0, 72, 80;
  --ion-color-corretor-blue-secondary-contrast: #ffffff;
  --ion-color-corretor-blue-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-corretor-blue-secondary-shade: #003f46;
  --ion-color-corretor-blue-secondary-tint: #1a5a62;

  /** corretor-blue-tertiary **/
  --ion-color-corretor-blue-tertiary: #defcff;
  --ion-color-corretor-blue-tertiary-rgb: 222, 252, 255;
  --ion-color-corretor-blue-tertiary-contrast: #000000;
  --ion-color-corretor-blue-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-corretor-blue-tertiary-shade: #c3dee0;
  --ion-color-corretor-blue-tertiary-tint: #e1fcff;

  /** corretor-blue-quaternary **/
  --ion-color-corretor-blue-quaternary: #cffaff;
  --ion-color-corretor-blue-quaternary-rgb: 207, 250, 255;
  --ion-color-corretor-blue-quaternary-contrast: #000000;
  --ion-color-corretor-blue-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-corretor-blue-quaternary-shade: #b6dce0;
  --ion-color-corretor-blue-quaternary-tint: #d4fbff;

  /** corretor-green-primary **/
  --ion-color-corretor-green-primary: #76e41e;
  --ion-color-corretor-green-primary-rgb: 118, 228, 30;
  --ion-color-corretor-green-primary-contrast: #004850;
  --ion-color-corretor-green-primary-contrast-rgb: 0, 72, 80;
  --ion-color-corretor-green-primary-shade: #68c91a;
  --ion-color-corretor-green-primary-tint: #84e735;

  /** corretor-green-secondary **/
  --ion-color-corretor-green-secondary: #73c431;
  --ion-color-corretor-green-secondary-rgb: 115, 196, 49;
  --ion-color-corretor-green-secondary-contrast: #000000;
  --ion-color-corretor-green-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-corretor-green-secondary-shade: #65ac2b;
  --ion-color-corretor-green-secondary-tint: #81ca46;

  /** corretor-green-tertiary **/
  --ion-color-corretor-green-tertiary: #e5ffd0;
  --ion-color-corretor-green-tertiary-rgb: 229, 255, 208;
  --ion-color-corretor-green-tertiary-contrast: #000000;
  --ion-color-corretor-green-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-corretor-green-tertiary-shade: #cae0b7;
  --ion-color-corretor-green-tertiary-tint: #e8ffd5;

/** black **/
  --ion-color-black: #000000;
	--ion-color-black-rgb: 0,0,0;
	--ion-color-black-contrast: #ffffff;
	--ion-color-black-contrast-rgb: 255,255,255;
	--ion-color-black-shade: #000000;
	--ion-color-black-tint: #1a1a1a;

  // ###################################
}

.ion-color-green-primary {
  --ion-color-base: var(--ion-color-green-primary);
  --ion-color-base-rgb: var(--ion-color-green-primary-rgb);
  --ion-color-contrast: var(--ion-color-green-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-primary-shade);
  --ion-color-tint: var(--ion-color-green-primary-tint);
}

.ion-color-green-secondary {
  --ion-color-base: var(--ion-color-green-secondary);
  --ion-color-base-rgb: var(--ion-color-green-secondary-rgb);
  --ion-color-contrast: var(--ion-color-green-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-secondary-shade);
  --ion-color-tint: var(--ion-color-green-secondary-tint);
}

.ion-color-green-tertiary {
  --ion-color-base: var(--ion-color-green-tertiary);
  --ion-color-base-rgb: var(--ion-color-green-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-green-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-tertiary-shade);
  --ion-color-tint: var(--ion-color-green-tertiary-tint);
}

.ion-color-blue-primary {
  --ion-color-base: var(--ion-color-blue-primary);
  --ion-color-base-rgb: var(--ion-color-blue-primary-rgb);
  --ion-color-contrast: var(--ion-color-blue-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-primary-shade);
  --ion-color-tint: var(--ion-color-blue-primary-tint);
}

.ion-color-blue-secondary {
  --ion-color-base: var(--ion-color-blue-secondary);
  --ion-color-base-rgb: var(--ion-color-blue-secondary-rgb);
  --ion-color-contrast: var(--ion-color-blue-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-secondary-shade);
  --ion-color-tint: var(--ion-color-blue-secondary-tint);
}

.ion-color-blue-tertiary {
  --ion-color-base: var(--ion-color-blue-tertiary);
  --ion-color-base-rgb: var(--ion-color-blue-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-blue-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-tertiary-shade);
  --ion-color-tint: var(--ion-color-blue-tertiary-tint);
}

.ion-color-blue-quaternary {
  --ion-color-base: var(--ion-color-blue-quaternary);
  --ion-color-base-rgb: var(--ion-color-blue-quaternary-rgb);
  --ion-color-contrast: var(--ion-color-blue-quaternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-quaternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-quaternary-shade);
  --ion-color-tint: var(--ion-color-blue-quaternary-tint);
}

.ion-color-blue-fifth {
  --ion-color-base: var(--ion-color-blue-fifth);
  --ion-color-base-rgb: var(--ion-color-blue-fifth-rgb);
  --ion-color-contrast: var(--ion-color-blue-fifth-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-fifth-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-fifth-shade);
  --ion-color-tint: var(--ion-color-blue-fifth-tint);
}

.ion-color-blue-header {
  --ion-color-base: var(--ion-color-blue-header);
  --ion-color-base-rgb: var(--ion-color-blue-header-rgb);
  --ion-color-contrast: var(--ion-color-blue-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-header-shade);
  --ion-color-tint: var(--ion-color-blue-header-tint);
}

.ion-color-blue-menu {
  --ion-color-base: var(--ion-color-blue-menu);
  --ion-color-base-rgb: var(--ion-color-blue-menu-rgb);
  --ion-color-contrast: var(--ion-color-blue-menu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-menu-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-menu-shade);
  --ion-color-tint: var(--ion-color-blue-menu-tint);
}

.ion-color-blue-menu-secondary {
  --ion-color-base: var(--ion-color-blue-menu-secondary);
  --ion-color-base-rgb: var(--ion-color-blue-menu-secondary-rgb);
  --ion-color-contrast: var(--ion-color-blue-menu-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-menu-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-menu-secondary-shade);
  --ion-color-tint: var(--ion-color-blue-menu-secondary-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

// novo layout
.ion-color-corretor-menu {
  --ion-color-base: var(--ion-color-corretor-menu);
  --ion-color-base-rgb: var(--ion-color-corretor-menu-rgb);
  --ion-color-contrast: var(--ion-color-corretor-menu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-menu-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-menu-shade);
  --ion-color-tint: var(--ion-color-corretor-menu-tint);
}

.ion-color-corretor-menu-secondary {
  --ion-color-base: var(--ion-color-corretor-menu-secondary);
  --ion-color-base-rgb: var(--ion-color-corretor-menu-secondary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-menu-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-menu-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-menu-secondary-shade);
  --ion-color-tint: var(--ion-color-corretor-menu-secondary-tint);
}

.ion-color-corretor-blue-primary {
  --ion-color-base: var(--ion-color-corretor-blue-primary);
  --ion-color-base-rgb: var(--ion-color-corretor-blue-primary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-blue-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-blue-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-blue-primary-shade);
  --ion-color-tint: var(--ion-color-corretor-blue-primary-tint);
}

.ion-color-corretor-blue-secondary {
  --ion-color-base: var(--ion-color-corretor-blue-secondary);
  --ion-color-base-rgb: var(--ion-color-corretor-blue-secondary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-blue-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-blue-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-blue-secondary-shade);
  --ion-color-tint: var(--ion-color-corretor-blue-secondary-tint);
}

.ion-color-corretor-blue-tertiary {
  --ion-color-base: var(--ion-color-corretor-blue-tertiary);
  --ion-color-base-rgb: var(--ion-color-corretor-blue-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-blue-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-blue-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-blue-tertiary-shade);
  --ion-color-tint: var(--ion-color-corretor-blue-tertiary-tint);
}

.ion-color-corretor-blue-quaternary {
  --ion-color-base: var(--ion-color-corretor-blue-quaternary);
  --ion-color-base-rgb: var(--ion-color-corretor-blue-quaternary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-blue-quaternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-blue-quaternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-blue-quaternary-shade);
  --ion-color-tint: var(--ion-color-corretor-blue-quaternary-tint);
}

.ion-color-corretor-green-primary {
  --ion-color-base: var(--ion-color-corretor-green-primary);
  --ion-color-base-rgb: var(--ion-color-corretor-green-primary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-green-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-green-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-green-primary-shade);
  --ion-color-tint: var(--ion-color-corretor-green-primary-tint);
}

.ion-color-corretor-green-secondary {
  --ion-color-base: var(--ion-color-corretor-green-secondary);
  --ion-color-base-rgb: var(--ion-color-corretor-green-secondary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-green-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-green-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-green-secondary-shade);
  --ion-color-tint: var(--ion-color-corretor-green-secondary-tint);
}

.ion-color-corretor-green-tertiary {
  --ion-color-base: var(--ion-color-corretor-green-tertiary);
  --ion-color-base-rgb: var(--ion-color-corretor-green-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-corretor-green-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-corretor-green-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-corretor-green-tertiary-shade);
  --ion-color-tint: var(--ion-color-corretor-green-tertiary-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black-rgb);
	--ion-color-contrast: var(--ion-color-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-black-shade);
	--ion-color-tint: var(--ion-color-black-tint);
}

// ###########################

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-green-primary: #a7c03d;
    --ion-color-green-primary-rgb: 167, 192, 61;
    --ion-color-green-primary-contrast: #000000;
    --ion-color-green-primary-contrast-rgb: 0, 0, 0;
    --ion-color-green-primary-shade: #93a936;
    --ion-color-green-primary-tint: #b0c650;

    --ion-color-green-secondary: #556318;
    --ion-color-green-secondary-rgb: 85, 99, 24;
    --ion-color-green-secondary-contrast: #ffffff;
    --ion-color-green-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-green-secondary-shade: #4b5715;
    --ion-color-green-secondary-tint: #66732f;

    --ion-color-green-tertiary: #313910;
    --ion-color-green-tertiary-rgb: 49, 57, 16;
    --ion-color-green-tertiary-contrast: #ffffff;
    --ion-color-green-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-green-tertiary-shade: #2b320e;
    --ion-color-green-tertiary-tint: #464d28;

    --ion-color-blue-primary: #16bcef;
    --ion-color-blue-primary-rgb: 22, 188, 239;
    --ion-color-blue-primary-contrast: #000000;
    --ion-color-blue-primary-contrast-rgb: 0, 0, 0;
    --ion-color-blue-primary-shade: #13a5d2;
    --ion-color-blue-primary-tint: #2dc3f1;

    --ion-color-blue-secondary: #1764a6;
    --ion-color-blue-secondary-rgb: 23, 100, 166;
    --ion-color-blue-secondary-contrast: #ffffff;
    --ion-color-blue-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-blue-secondary-shade: #145892;
    --ion-color-blue-secondary-tint: #2e74af;

    --ion-color-blue-tertiary: #002456;
    --ion-color-blue-tertiary-rgb: 0, 36, 86;
    --ion-color-blue-tertiary-contrast: #ffffff;
    --ion-color-blue-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-blue-tertiary-shade: #00204c;
    --ion-color-blue-tertiary-tint: #1a3a67;

    --ion-color-blue-quaternary: #1350a5;
    --ion-color-blue-quaternary-rgb: 19, 80, 165;
    --ion-color-blue-quaternary-contrast: #ffffff;
    --ion-color-blue-quaternary-contrast-rgb: 255, 255, 255;
    --ion-color-blue-quaternary-shade: #114691;
    --ion-color-blue-quaternary-tint: #2b62ae;

    --ion-color-blue-fifth: #0184c7;
    --ion-color-blue-fifth-rgb: 1, 132, 199;
    --ion-color-blue-fifth-contrast: #ffffff;
    --ion-color-blue-fifth-contrast-rgb: 255, 255, 255;
    --ion-color-blue-fifth-shade: #0174af;
    --ion-color-blue-fifth-tint: #1a90cd;

    --ion-color-blue-header: #032a41;
    --ion-color-blue-header-rgb: 3, 42, 65;
    --ion-color-blue-header-contrast: #ffffff;
    --ion-color-blue-header-contrast-rgb: 255, 255, 255;
    --ion-color-blue-header-shade: #032539;
    --ion-color-blue-header-tint: #1c3f54;

    --ion-color-blue-menu: #000d1f;
    --ion-color-blue-menu-rgb: 0, 13, 31;
    --ion-color-blue-menu-contrast: #ffffff;
    --ion-color-blue-menu-contrast-rgb: 255, 255, 255;
    --ion-color-blue-menu-shade: #000b1b;
    --ion-color-blue-menu-tint: #1a2535;

    --ion-color-blue-menu-secondary: #00337b;
    --ion-color-blue-menu-secondary-rgb: 0, 51, 123;
    --ion-color-blue-menu-secondary-contrast: #ffffff;
    --ion-color-blue-menu-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-blue-menu-secondary-shade: #002d6c;
    --ion-color-blue-menu-secondary-tint: #1a4788;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    // novo layout
    /** corretor-menu **/
    --ion-color-corretor-menu: #00a7ba;
    --ion-color-corretor-menu-rgb: 0, 167, 186;
    --ion-color-corretor-menu-contrast: #ffffff;
    --ion-color-corretor-menu-contrast-rgb: 255, 255, 255;
    --ion-color-corretor-menu-shade: #0093a4;
    --ion-color-corretor-menu-tint: #1ab0c1;

    /** corretor-menu-secondary **/
    --ion-color-corretor-menu-secondary: #004850;
    --ion-color-corretor-menu-secondary-rgb: 0, 72, 80;
    --ion-color-corretor-menu-secondary-contrast: #ffffff;
    --ion-color-corretor-menu-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-corretor-menu-secondary-shade: #003f46;
    --ion-color-corretor-menu-secondary-tint: #1a5a62;

    /** corretor-blue-primary **/
    --ion-color-corretor-blue-primary: #00a7ba;
    --ion-color-corretor-blue-primary-rgb: 0, 167, 186;
    --ion-color-corretor-blue-primary-contrast: #ffffff;
    --ion-color-corretor-blue-primary-contrast-rgb: 255, 255, 255;
    --ion-color-corretor-blue-primary-shade: #0093a4;
    --ion-color-corretor-blue-primary-tint: #1ab0c1;

    /** corretor-blue-secondary **/
    --ion-color-corretor-blue-secondary: #004850;
    --ion-color-corretor-blue-secondary-rgb: 0, 72, 80;
    --ion-color-corretor-blue-secondary-contrast: #ffffff;
    --ion-color-corretor-blue-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-corretor-blue-secondary-shade: #003f46;
    --ion-color-corretor-blue-secondary-tint: #1a5a62;

    /** corretor-blue-tertiary **/
    --ion-color-corretor-blue-tertiary: #defcff;
    --ion-color-corretor-blue-tertiary-rgb: 222, 252, 255;
    --ion-color-corretor-blue-tertiary-contrast: #000000;
    --ion-color-corretor-blue-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-corretor-blue-tertiary-shade: #c3dee0;
    --ion-color-corretor-blue-tertiary-tint: #e1fcff;

    /** corretor-blue-quaternary **/
    --ion-color-corretor-blue-quaternary: #cffaff;
    --ion-color-corretor-blue-quaternary-rgb: 207, 250, 255;
    --ion-color-corretor-blue-quaternary-contrast: #000000;
    --ion-color-corretor-blue-quaternary-contrast-rgb: 0, 0, 0;
    --ion-color-corretor-blue-quaternary-shade: #b6dce0;
    --ion-color-corretor-blue-quaternary-tint: #d4fbff;

    /** corretor-green-primary **/
    --ion-color-corretor-green-primary: #76e41e;
    --ion-color-corretor-green-primary-rgb: 118, 228, 30;
    --ion-color-corretor-green-primary-contrast: #000000;
    --ion-color-corretor-green-primary-contrast-rgb: 0, 0, 0;
    --ion-color-corretor-green-primary-shade: #68c91a;
    --ion-color-corretor-green-primary-tint: #84e735;

    /** corretor-green-secondary **/
    --ion-color-corretor-green-secondary: #73c431;
    --ion-color-corretor-green-secondary-rgb: 115, 196, 49;
    --ion-color-corretor-green-secondary-contrast: #000000;
    --ion-color-corretor-green-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-corretor-green-secondary-shade: #65ac2b;
    --ion-color-corretor-green-secondary-tint: #81ca46;

    /** corretor-green-tertiary **/
    --ion-color-corretor-green-tertiary: #e5ffd0;
    --ion-color-corretor-green-tertiary-rgb: 229, 255, 208;
    --ion-color-corretor-green-tertiary-contrast: #000000;
    --ion-color-corretor-green-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-corretor-green-tertiary-shade: #cae0b7;
    --ion-color-corretor-green-tertiary-tint: #e8ffd5;

    // ###################################
  }

  .ion-color-green-primary {
    --ion-color-base: var(--ion-color-green-primary);
    --ion-color-base-rgb: var(--ion-color-green-primary-rgb);
    --ion-color-contrast: var(--ion-color-green-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-primary-shade);
    --ion-color-tint: var(--ion-color-green-primary-tint);
  }

  .ion-color-green-secondary {
    --ion-color-base: var(--ion-color-green-secondary);
    --ion-color-base-rgb: var(--ion-color-green-secondary-rgb);
    --ion-color-contrast: var(--ion-color-green-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-secondary-shade);
    --ion-color-tint: var(--ion-color-green-secondary-tint);
  }

  .ion-color-green-tertiary {
    --ion-color-base: var(--ion-color-green-tertiary);
    --ion-color-base-rgb: var(--ion-color-green-tertiary-rgb);
    --ion-color-contrast: var(--ion-color-green-tertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-tertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-tertiary-shade);
    --ion-color-tint: var(--ion-color-green-tertiary-tint);
  }

  .ion-color-blue-primary {
    --ion-color-base: var(--ion-color-blue-primary);
    --ion-color-base-rgb: var(--ion-color-blue-primary-rgb);
    --ion-color-contrast: var(--ion-color-blue-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-primary-shade);
    --ion-color-tint: var(--ion-color-blue-primary-tint);
  }

  .ion-color-blue-secondary {
    --ion-color-base: var(--ion-color-blue-secondary);
    --ion-color-base-rgb: var(--ion-color-blue-secondary-rgb);
    --ion-color-contrast: var(--ion-color-blue-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-secondary-shade);
    --ion-color-tint: var(--ion-color-blue-secondary-tint);
  }

  .ion-color-blue-tertiary {
    --ion-color-base: var(--ion-color-blue-tertiary);
    --ion-color-base-rgb: var(--ion-color-blue-tertiary-rgb);
    --ion-color-contrast: var(--ion-color-blue-tertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-tertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-tertiary-shade);
    --ion-color-tint: var(--ion-color-blue-tertiary-tint);
  }

  .ion-color-blue-quaternary {
    --ion-color-base: var(--ion-color-blue-quaternary);
    --ion-color-base-rgb: var(--ion-color-blue-quaternary-rgb);
    --ion-color-contrast: var(--ion-color-blue-quaternary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-quaternary-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-quaternary-shade);
    --ion-color-tint: var(--ion-color-blue-quaternary-tint);
  }

  .ion-color-blue-fifth {
    --ion-color-base: var(--ion-color-blue-fifth);
    --ion-color-base-rgb: var(--ion-color-blue-fifth-rgb);
    --ion-color-contrast: var(--ion-color-blue-fifth-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-fifth-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-fifth-shade);
    --ion-color-tint: var(--ion-color-blue-fifth-tint);
  }

  .ion-color-blue-header {
    --ion-color-base: var(--ion-color-blue-header);
    --ion-color-base-rgb: var(--ion-color-blue-header-rgb);
    --ion-color-contrast: var(--ion-color-blue-header-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-header-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-header-shade);
    --ion-color-tint: var(--ion-color-blue-header-tint);
  }

  .ion-color-blue-menu {
    --ion-color-base: var(--ion-color-blue-menu);
    --ion-color-base-rgb: var(--ion-color-blue-menu-rgb);
    --ion-color-contrast: var(--ion-color-blue-menu-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-menu-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-menu-shade);
    --ion-color-tint: var(--ion-color-blue-menu-tint);
  }

  .ion-color-blue-menu-secondary {
    --ion-color-base: var(--ion-color-blue-menu-secondary);
    --ion-color-base-rgb: var(--ion-color-blue-menu-secondary-rgb);
    --ion-color-contrast: var(--ion-color-blue-menu-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-menu-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-menu-secondary-shade);
    --ion-color-tint: var(--ion-color-blue-menu-secondary-tint);
  }

  .ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  // novo layout
  .ion-color-corretor-menu {
    --ion-color-base: var(--ion-color-corretor-menu);
    --ion-color-base-rgb: var(--ion-color-corretor-menu-rgb);
    --ion-color-contrast: var(--ion-color-corretor-menu-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-menu-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-menu-shade);
    --ion-color-tint: var(--ion-color-corretor-menu-tint);
  }

  .ion-color-corretor-menu-secondary {
    --ion-color-base: var(--ion-color-corretor-menu-secondary);
    --ion-color-base-rgb: var(--ion-color-corretor-menu-secondary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-menu-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-menu-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-menu-secondary-shade);
    --ion-color-tint: var(--ion-color-corretor-menu-secondary-tint);
  }

  .ion-color-corretor-blue-primary {
    --ion-color-base: var(--ion-color-corretor-blue-primary);
    --ion-color-base-rgb: var(--ion-color-corretor-blue-primary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-blue-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-blue-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-blue-primary-shade);
    --ion-color-tint: var(--ion-color-corretor-blue-primary-tint);
  }

  .ion-color-corretor-blue-secondary {
    --ion-color-base: var(--ion-color-corretor-blue-secondary);
    --ion-color-base-rgb: var(--ion-color-corretor-blue-secondary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-blue-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-blue-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-blue-secondary-shade);
    --ion-color-tint: var(--ion-color-corretor-blue-secondary-tint);
  }

  .ion-color-corretor-blue-tertiary {
    --ion-color-base: var(--ion-color-corretor-blue-tertiary);
    --ion-color-base-rgb: var(--ion-color-corretor-blue-tertiary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-blue-tertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-blue-tertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-blue-tertiary-shade);
    --ion-color-tint: var(--ion-color-corretor-blue-tertiary-tint);
  }

  .ion-color-corretor-blue-quaternary {
    --ion-color-base: var(--ion-color-corretor-blue-quaternary);
    --ion-color-base-rgb: var(--ion-color-corretor-blue-quaternary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-blue-quaternary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-blue-quaternary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-blue-quaternary-shade);
    --ion-color-tint: var(--ion-color-corretor-blue-quaternary-tint);
  }

  .ion-color-corretor-green-primary {
    --ion-color-base: var(--ion-color-corretor-green-primary);
    --ion-color-base-rgb: var(--ion-color-corretor-green-primary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-green-primary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-green-primary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-green-primary-shade);
    --ion-color-tint: var(--ion-color-corretor-green-primary-tint);
  }

  .ion-color-corretor-green-secondary {
    --ion-color-base: var(--ion-color-corretor-green-secondary);
    --ion-color-base-rgb: var(--ion-color-corretor-green-secondary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-green-secondary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-green-secondary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-green-secondary-shade);
    --ion-color-tint: var(--ion-color-corretor-green-secondary-tint);
  }

  .ion-color-corretor-green-tertiary {
    --ion-color-base: var(--ion-color-corretor-green-tertiary);
    --ion-color-base-rgb: var(--ion-color-corretor-green-tertiary-rgb);
    --ion-color-contrast: var(--ion-color-corretor-green-tertiary-contrast);
    --ion-color-contrast-rgb: var(--ion-color-corretor-green-tertiary-contrast-rgb);
    --ion-color-shade: var(--ion-color-corretor-green-tertiary-shade);
    --ion-color-tint: var(--ion-color-corretor-green-tertiary-tint);
  }

  // ###########################

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body {
  //   --ion-background-color: #000000;
  //   --ion-background-color-rgb: 0, 0, 0;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-color-step-50: #0d0d0d;
  //   --ion-color-step-100: #1a1a1a;
  //   --ion-color-step-150: #262626;
  //   --ion-color-step-200: #333333;
  //   --ion-color-step-250: #404040;
  //   --ion-color-step-300: #4d4d4d;
  //   --ion-color-step-350: #595959;
  //   --ion-color-step-400: #666666;
  //   --ion-color-step-450: #737373;
  //   --ion-color-step-500: #808080;
  //   --ion-color-step-550: #8c8c8c;
  //   --ion-color-step-600: #999999;
  //   --ion-color-step-650: #a6a6a6;
  //   --ion-color-step-700: #b3b3b3;
  //   --ion-color-step-750: #bfbfbf;
  //   --ion-color-step-800: #cccccc;
  //   --ion-color-step-850: #d9d9d9;
  //   --ion-color-step-900: #e6e6e6;
  //   --ion-color-step-950: #f2f2f2;

  //   --ion-item-background: #000000;

  //   --ion-card-background: #1c1c1d;
  // }

  // .ios ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  // .md body {
  //   --ion-background-color: #121212;
  //   --ion-background-color-rgb: 18, 18, 18;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255, 255, 255;

  //   --ion-border-color: #222222;

  //   --ion-color-step-50: #1e1e1e;
  //   --ion-color-step-100: #2a2a2a;
  //   --ion-color-step-150: #363636;
  //   --ion-color-step-200: #414141;
  //   --ion-color-step-250: #4d4d4d;
  //   --ion-color-step-300: #595959;
  //   --ion-color-step-350: #656565;
  //   --ion-color-step-400: #717171;
  //   --ion-color-step-450: #7d7d7d;
  //   --ion-color-step-500: #898989;
  //   --ion-color-step-550: #949494;
  //   --ion-color-step-600: #a0a0a0;
  //   --ion-color-step-650: #acacac;
  //   --ion-color-step-700: #b8b8b8;
  //   --ion-color-step-750: #c4c4c4;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dbdbdb;
  //   --ion-color-step-900: #e7e7e7;
  //   --ion-color-step-950: #f3f3f3;

  //   --ion-item-background: #1e1e1e;

  //   --ion-toolbar-background: #1f1f1f;

  //   --ion-tab-bar-background: #1f1f1f;

  //   --ion-card-background: #1e1e1e;
  // }
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "animate.css";

@font-face {
    font-family: "SFProText-Regular";
    src: url("./assets/fonts/SFProText-Regular.ttf");
}

* {
    font-family: "SFProText-Regular" !important;
}

ion-card {
    margin-inline: unset;
}

// css para o loading progress-bar
#backdrop {
    z-index: 2;
    opacity: 0.5;
    background: #000;
}

#backdropTransparent {
    z-index: 2;
    opacity: 0;
    background: #000;
}

#progress-bar {
    z-index: 3;
    opacity: 1;
    height: 5px;
}

#backdropExport {
    z-index: 2;
    opacity: .95;
    background: #000;
}

#progress-bar-export {
    z-index: 3;
    opacity: .8;
    height: 5px;
}

#spinnerExport {
    z-index: 4;
    display: flex;
    margin: auto;
    color: #76e41e;
    width: 50px;
    height: 50px;
    margin-top: calc(50vh - 50px);
}

#textExport {
    z-index: 4;
    display: flex;
    margin: auto;
    color: #76e41e;
    width: auto;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
}

// css alert
.alert-title.sc-ion-alert-ios {
    color: #76e41e;
}

.alert-message.sc-ion-alert-ios {
    color: #73c431;
}

.alert-button.sc-ion-alert-ios {
    color: #00a7ba;
}

.alert-button.sc-ion-alert-ios:last-child {
    color: #76e41e;
}

.alertExport .alert-button.sc-ion-alert-ios:last-child {
    color: #00a7ba !important;
    font-weight: 300;
}

.alertExport .cancel {
    color: #eb445a !important;
    font-weight: 300;
}

.alertExport .alert-title.sc-ion-alert-ios {
    color: #004850;
}

.alertExport .alert-message.sc-ion-alert-ios {
    color: #004850;
}

[aria-checked="true"].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
    border-color: #76e41e;
    background-color: #76e41e;
}

[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
    color: #76e41e;
}

[aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
    border-color: #76e41e;
}

.alert-tappable.sc-ion-alert-ios {
    text-align: center;
}

.alert-radio-label.sc-ion-alert-ios {
    margin-right: -30px;
}

.irDownload {
    color: #76e41e !important;
    font-weight: bold !important;
}

.contactDelete {
    color: #eb445a !important;
    font-weight: bold !important;
}

.irWhatsApp {
    color: #34af23 !important;
    background-image: url("assets/icon/logo-whatsapp.svg") !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    zoom: 90%;
}

// css toast
.toast {
    --start: none;
}

// css picker
.picker-button.sc-ion-picker-ios,
.picker-button.ion-activated.sc-ion-picker-ios {
    color: #76e41e;
}

.picker-opt {
    color: #2f515d;
}

.picker-above-highlight.sc-ion-picker-ios {
    border-bottom: 1px solid #66a4bb;
}

.picker-below-highlight.sc-ion-picker-ios {
    border-top: 1px solid #66a4bb;
}

.picker-wrapper.sc-ion-picker-ios {
    max-width: 100%;
}

@media only screen and (max-width: 767px) {
    .toast {
        --start: 10px;
    }
}

.alertSolicitacao {
    --height: 300px;
}

.alertSolicitacao textarea.alert-input.sc-ion-alert-ios {
    height: 110px;
}

.classModalNotification {
    --backdrop-opacity: 0 !important;
    --width: 360px;
    --max-width: 360px;
    --height: 250px;
    --max-height: 300px;
    --border-radius: 0px 0px 10px 10px;
}

.classModalNotification .modal-wrapper.sc-ion-modal-ios {
    margin: 83px 10px auto auto;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

#searchFAQ .searchbar-input.sc-ion-searchbar-ios {
    font-size: 14px;
}

.sc-ion-select-popover-h {
    overflow: auto !important;
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover,
.sc-ion-select-popover-h ion-label.sc-ion-select-popover {
    font-size: 14px;
    font-weight: 600;
    color: #004850;

    ion-radio {
        --color-checked: #004850 !important;
    }
}

ion-radio.radio-checked::part(mark) {
    border-color: #004850;
}

.native-input[disabled].sc-ion-input-ios {
    opacity: 1 !important;
}

.select-disabled {
    opacity: 1 !important;
}

.modal-your-data {
    --border-radius: 0px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
    --width: 300px;
    --height: calc(100vh - 83px);
    --min-height: calc(100vh - 83px);
    --box-shadow: 0px 3px 4px 1px rgba(162, 164, 171, 0.5);
}

.modal-your-data .modal-wrapper.sc-ion-modal-ios,
.modal-your-data .modal-shadow.sc-ion-modal-ios {
    margin-right: 0;
    margin-left: auto;
    margin-top: 83px;
    right: 0;
}

.sc-ion-popover-ios-h {
    --width: auto !important;
}


// ajuste select popover
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

#frame_pdf {
    border: none;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 353px;
    background: #FFFFFF;
}

#frame_pdf ion-img {
    width: 250px;
    height: auto;
    margin: auto;
}

// css create files

.create-files .action-sheet-button.sc-ion-action-sheet-ios:hover {
    color: #00a7ba;
}

.create-files .action-sheet-button.sc-ion-action-sheet-ios {
    color: #004850;
    border-bottom: 0.5px dashed #00a7ba;
}

.create-files .action-sheet-group-cancel .action-sheet-button.sc-ion-action-sheet-ios {
    border-bottom: none;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
}

.modal-commissions {
    --border-radius: 0px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
    --width: 100vw;
    --height: 100%;
    --min-height: 100%;
    --box-shadow: 0px 3px 4px 1px rgba(162, 164, 171, 0.5);
}

.modal-commissions-report {
    --border-radius: 10px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
    --width: 50vw;
    --height: 60vh;
    --min-height: 60vh;
    --box-shadow: 0px 3px 4px 1px rgba(162, 164, 171, 0.5);

    @media only screen and (max-width: 767px) {
        --width: 100vw;
        --height: 100%;
        --min-height: 100%;
    }
}

.modal-campaigns {
    --border-radius: 0px;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.2);
    --width: 100vw;
    --height: 100%;
    --min-height: 100%;
    --box-shadow: 0px 3px 4px 1px rgba(162, 164, 171, 0.5);
}